/**
 A LIST CONTAIN THE EVENTS THAT WILL NOT BE MUTED
 **/
import { Experiments } from '@wix/thunderbolt-symbols'

export const ThunderboltMutingBlackList: Set<string> = new Set([
	'page-navigation',
	'page_features_loaded',
	'multilingual_init',
	'partially_visible',
	'script_loaded',
	'init_app_for_page',
	'create_controllers',
	'controller_page_ready',
	'await_controller_promise',
])

export const AppsMutingWhiteList: Set<string> = new Set([
	'1380b703-ce81-ff05-f115-39571d94dfcd', // ECOM
])

const MUTING_PERCENTAGE = 90 / 100 // THE PERCENTAGE OF EVENTS THAT WILL BE MUTED

/**
 * SAMPLING:
 * We get MUTING_PERCENTAGE, and we use it to calculate how many events we should mute.
 * If we got 0.1 then for 90% of the cases we run thunderbolt -> muteThunderboltEvents = true.
 * Meaning that the events will be muted 90% of the time.
 * muteThunderboltEvents = true => events aren't reported.
 * muteThunderboltEvents = false => events are reported.
 */

export const shouldMuteThunderboltEvents = (experiments: Experiments, requestUrl: string) => {
	const searchParam = new URL(requestUrl).searchParams
	if (searchParam.has('sampleEvents')) {
		return searchParam.get('sampleEvents') === 'true'
	}

	return experiments['specs.thunderbolt.sampleThunderboltEvents'] ? Math.random() < MUTING_PERCENTAGE : false
}
